import './PageNotFound.css';

function PageNotFound() {
    return (
        <div className="PageNotFound-container">
            <h1 className="PageNotFound-404-header">404</h1>
            <h6 className="PageNotFound-message">Oops! That page can't be found.</h6>
        </div>
    );
}

export default PageNotFound;
