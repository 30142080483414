/* Blockchains Config */
const blockchains = {
    "production": {
        "chainId": "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
        "name": "EOS",
        "rpcEndpoints": [{
            "protocol": "https",
            "host": "eos.greymass.com",
            "port": 443
        }, {
            "protocol": "https",
            "host": "eos.api.eosnation.io",
            "port": 443
        }, {
            "protocol": "https",
            "host": "api.eoseoul.io",
            "port": 443
        }]
    },
    "development": {
        "chainId": "73e4385a2708e6d7048834fbc1079f2fabb17b3c125b146af438971e90716c4d",
        "name": "Jungle 4 (Testnet)",
        "rpcEndpoints": [{
            "protocol": "https",
            "host": "jungle4.greymass.com",
            "port": 443
        }]
    }
};

/* Contract Accounts Config */
const contracts = {
    'production': 'badgeitoneos',
    'development': 'badzitoneos1'
};

/* Explorers Config */
const explorers = {
    'production': new URL('https://bloks.io'),
    'development': new URL('https://jungle4.eosq.eosnation.io')
};

/* dfuse Config */
const dfuseNetworks = {
    'production': 'eos.dfuse.eosnation.io',
    'development': 'jungle4.dfuse.eosnation.io'
};

/* Exports */
export const BLOCKCHAIN = blockchains[process.env.NODE_ENV];
export const CONTRACT_NAME = contracts[process.env.NODE_ENV];
export const EXPLORER_URL = explorers[process.env.NODE_ENV];
export const DFUSE_NETWORK = dfuseNetworks[process.env.NODE_ENV];
export const DFUSE_API_KEY = '6816e84ab67a513274f464efd6b2194b';

export const transactionDetailsURL = function(trxHash) {
    if (EXPLORER_URL.host === 'bloks.io') {
        return new URL('transaction/' + trxHash, EXPLORER_URL).toString();
    }
    else {
        return new URL('tx/' + trxHash, EXPLORER_URL).toString();
    }
};
