import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import ToastMessage from '../ToastMessage';
import { SharedStateContext } from '../../Helpers/SharedState';
import { CONTRACT_NAME } from '../../config';

class RevokeTokenModal extends React.Component {
    static contextType = SharedStateContext;

    get sharedState() {
        const [state] = this.context;
        return state;
    }

    constructor(props) {
        super(props);

        this.state = {
            isTransacting: false,
            errorMessage: undefined,
            trxHash: undefined
        };

        this.reason = undefined;

        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.hideSuccessMessage = this.hideSuccessMessage.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.close = this.close.bind(this);
    };

    // Token Creation
    handleFormSubmit(event) {
        const form = event.target;
        this.reason = form['reason'].value.trim() ?? ''; // Must be specified
        event.preventDefault();
        this.revokeToken();
    };

    async revokeToken() {
        this.setState({isTransacting: true});

        try {
            const activeUser = this.sharedState.ual.activeUser;
            const trx = this.revokeTokenTransaction(activeUser, this.props.token, this.reason);
            const response = await activeUser.signTransaction(trx, {broadcast: true});

            this.close();
            this.props.onTokenRevoked();
            this.showSuccessMessage(response.transactionId);
        }
        catch(error) {
            this.showErrorMessage(error);
        }

        this.setState({isTransacting: false});
    };

    revokeTokenTransaction(user, token, reason) {
        return {
            actions: [{
                account: CONTRACT_NAME,
                name: 'revoke',
                authorization: [{
                    actor: user.accountName,
                    permission: 'active'
                }],
                data: {
                    'owner': token.owner,
                    'token_id': token.id,
                    'memo': reason
                }
            }]
        }
    };

    // Toast Messages
    showErrorMessage(error) {
        if (error?.cause?.type === 'signature_rejected') {
            this.setState({errorMessage: 'The signature request rejected'});
        }
        else {
            // Scatter & Anchor produce different errors
            const cause = ('[' + (error?.cause?.message) + ']')
            this.setState({errorMessage: error.message + ' ' + cause});
            console.error(error?.cause || error);
        }
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    }

    showSuccessMessage(trxHash) {
        this.setState({trxHash});
    };

    hideSuccessMessage() {
        this.setState({trxHash: undefined});
    };

    // Misc
    close() {
        this.props.onCloseClicked();
    };

    render() {
        return (
            <>
                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />

                <ToastMessage.TransactionSigned
                    isShown={!!this.state.trxHash}
                    trxHash={this.state.trxHash}
                    onAutoClosed={this.hideSuccessMessage}
                />

                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} contentClassName="modal-content-dark" backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title>Revoke a Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form id="revokeTokenForm" onSubmit={this.handleFormSubmit}>
                            <Form.Group className="mb-3" controlId="reason">
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as="textarea" className="capitalizeFirstLetter" rows={3} maxLength="256" autoFocus/>
                                <Form.Text className="text-muted">Optional memo describing a reason for the token's revocation.</Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        {!this.state.isTransacting &&
                            <Button variant="secondary" onClick={this.close}>Cancel</Button>
                        }

                        <Button type="submit" form="revokeTokenForm" variant="danger" disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">Revoke</span>
                                </>
                                :
                                'Revoke'
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
};

export default RevokeTokenModal;
