import React from 'react';
import Stack from 'react-bootstrap/Stack';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import ReactGA from 'react-ga4';
import { Link, useNavigate } from 'react-router-dom';

import CreateTokenModal from './Modals/CreateTokenModal';
import IssueTokenModal from './Modals/IssueTokenModal';

import { fetchAccountNameSuggestions } from '../Helpers/BadgesRPC';
import { SharedStateContext } from '../Helpers/SharedState';
import * as utils from '../Helpers/Utils';

import './Header.css';
import logoImage from '../Assets/Logo.png';
import smallLogoImage from '../Assets/Logo-small.png';

class Header extends React.Component {
    static contextType = SharedStateContext;

    get sharedState() {
        const [state] = this.context;
        return state;
    }

    constructor(props) {
        super(props);

        this.state = {
            isCreateTokenModalShown: false,
            isIssueTokenModalShown: false,
            isSuggestionsListHidden: true,
            accountSuggestions: []
        };

        this.searchBarInput = null;

        this.showCreateTokenModal = this.showCreateTokenModal.bind(this);
        this.hideCreateTokenModal = this.hideCreateTokenModal.bind(this);

        this.showIssueTokenModal = this.showIssueTokenModal.bind(this);
        this.hideIssueTokenModal = this.hideIssueTokenModal.bind(this);

        this.fetchNameSuggestions = this.fetchNameSuggestions.bind(this);
        this.accountSearchingSuggestions = this.accountSearchingSuggestions.bind(this);
        this.setDefaultSearchSuggestions = this.setDefaultSearchSuggestions.bind(this);
        this.resetSearchSuggestions = this.resetSearchSuggestions.bind(this);
    }

    // TokenModals
    showCreateTokenModal() {
        ReactGA.send({hitType: 'pageview', page: 'create-token'});
        this.setState({isCreateTokenModalShown: true});
    };

    hideCreateTokenModal() {
        this.setState({isCreateTokenModalShown: false});
    };

    showIssueTokenModal() {
        ReactGA.send({hitType: 'pageview', page: 'issue-token'});
        this.setState({isIssueTokenModalShown: true});
    };

    hideIssueTokenModal() {
        this.setState({isIssueTokenModalShown: false});
    };

    // Search Suggestions
    searchAccountName(accountName) {
        this.searchBarInput.value = '';
        this.searchBarInput.blur();
        this.props.navigate('/account/' + accountName.toLowerCase());
    }

    fetchNameSuggestions(event) {
        const accountName = event.target.value.trim().toLowerCase();

        if (utils.isValidEosioName(accountName)) {
            fetchAccountNameSuggestions(accountName)
                .then(suggestions => {
                    const currentAccountName = this.searchBarInput.value.trim().toLowerCase();

                    // The response could be returned back too late
                    if (currentAccountName === accountName) {
                        if (suggestions.length === 1) {
                            this.searchAccountName(suggestions[0]);
                        }
                        else {
                            this.setState({accountSuggestions: suggestions})
                        }
                    }
                })
                .catch(() => this.resetSearchSuggestions());
        }
        else {
            this.resetSearchSuggestions();
        }
    }

    accountSearchingSuggestions() {
        const accountSuggestions = this.state.accountSuggestions;
        const suggestionsLength = accountSuggestions.length;

        return accountSuggestions
            .map((accountName, index) => {
                return (
                    <React.Fragment key={accountName}>
                        <ListGroup.Item className="Header-searchBarSuggestionsListItem" onClick={() => this.searchAccountName(accountName)}>{accountName}</ListGroup.Item>
                        { index !== (suggestionsLength - 1) &&
                            <hr className="Header-searchBarSuggestionsListDivider"/>
                        }
                    </React.Fragment>
                );
            });
    }

    setDefaultSearchSuggestions() {
        // Makes it easier to access the account for the logged in user
        const accountName = this.sharedState.ual.activeUser?.accountName;
        this.setState({accountSuggestions: accountName ? [accountName] : []});
    }

    resetSearchSuggestions() {
        this.setState({accountSuggestions: []});
    }

    // Components
    getAccountButton() {
        const ual = this.sharedState.ual;
        const user = ual.activeUser;

        if (!user) {
            return <Button variant="primary" onClick={ual.showModal}>Login</Button>;
        }
        else {
            const sbtToken = this.sharedState.sbtToken;
            const tokenStateUnknown = (sbtToken === undefined); // Unknown or an error occurred while checking
            const tokenNotCreated = (sbtToken === null);

            return (
                <DropdownButton id="dropdown-basic-button" menuVariant="dark" title={user.accountName}>
                    <Dropdown.Item onClick={this.showCreateTokenModal} disabled={sbtToken || tokenStateUnknown}>Create a token</Dropdown.Item>
                    <Dropdown.Item onClick={this.showIssueTokenModal} disabled={tokenNotCreated || tokenStateUnknown}>Issue a token</Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item onClick={ual.logout}>Logout</Dropdown.Item>
                </DropdownButton>
            );
        }
    }

    // React
    render() {
        const accountButton = this.getAccountButton();

        return (
            <header>
                <CreateTokenModal isShown={this.state.isCreateTokenModalShown} onCloseClicked={this.hideCreateTokenModal}/>
                <IssueTokenModal isShown={this.state.isIssueTokenModalShown} onCloseClicked={this.hideIssueTokenModal}/>

                {/* Main Content */}
                <Stack className="Header-stack" direction="horizontal" gap={3}>
                    <Link to="/">
                        <picture>
                            <source srcSet={smallLogoImage} media="(max-width: 450px)"/>
                            <img src={logoImage} className="Header-logo" alt="logo"/>
                        </picture>
                    </Link>

                    <FormGroup className="Header-searchBarGroup ms-auto">
                        <FormControl
                            className="Header-searchBar"
                            type="search"
                            placeholder="Search Account"
                            autoComplete="off"
                            ref={ref => { this.searchBarInput = ref }}
                            onChange={this.fetchNameSuggestions}
                            onBlur={() => {
                                // Use `setTimeout` otherwise click on the item won't be registered
                                setTimeout(() => { this.setState({isSuggestionsListHidden: true})}, 150);
                            }}
                            onFocus={event => {
                                const value = event.target.value.trim();

                                if (value === '') {
                                    this.setDefaultSearchSuggestions();
                                }

                                this.setState({isSuggestionsListHidden: false});
                            }}
                            onKeyUp={event => {
                                const value = event.target.value.trim();

                                if (event.key === 'Enter' && value !== '') {
                                    this.searchAccountName(value);
                                }
                                else if (value === '') {
                                    this.setDefaultSearchSuggestions();
                                }
                        }}/>

                        <ListGroup className="Header-searchBarSuggestionsList" style={{display: this.state.isSuggestionsListHidden ? 'none' : 'flex'}}>
                            {this.state.accountSuggestions.length > 0 && this.accountSearchingSuggestions()}
                        </ListGroup>
                    </FormGroup>

                    {accountButton}
                </Stack>
            </header>
        );
    }
}

const WrappedHeader = (props) => {
    return <Header {...props} navigate={useNavigate()}/>
};

export default WrappedHeader;
