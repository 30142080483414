import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import CloseButton from 'react-bootstrap/CloseButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

import RevokeTokenModal from './Modals/RevokeTokenModal';
import BurnTokenModal from './Modals/BurnTokenModal';
import PulseLoader from './Widgets/PulseLoader';
import DetailedError from './Widgets/DetailedError';

import { SharedStateContext } from '../Helpers/SharedState';
import { fetchIssuedTokens, fetchCreatedTokens, fetchAccount } from '../Helpers/BadgesRPC';
import '../Helpers/StringUtils';
import * as utils from '../Helpers/Utils';

import './TokensList.css';
import noImageFoundIcon from '../Assets/NoImageFoundIcon.png';

class TokensList extends React.Component {
    static contextType = SharedStateContext;
    #TOKEN_DETAILS_CACHE_KEY = 'token_details_cached';

    get sharedState() {
        const [state] = this.context;
        return state;
    }

    constructor(props) {
        super(props);

        const accountName = props.accountName;
        const isValidAccountName = utils.isValidEosioName(accountName);

        this.state = {
            tokens: [],
            isValidAccountName: isValidAccountName,
            isFetchingData: false,
            accountNameExists: undefined,
            fetchingError: undefined,
            burnToken: undefined,
            revokeToken: undefined
        };

        this.setupDocumentTitle();

        this.fetchTokens = this.fetchTokens.bind(this);
        this.retryTokensFetch = this.retryTokensFetch.bind(this);
        this.showRevokeTokenModal = this.showRevokeTokenModal.bind(this);
        this.hideRevokeTokenModal = this.hideRevokeTokenModal.bind(this);
        this.showBurnTokenModal = this.showBurnTokenModal.bind(this);
        this.hideBurnTokenModal = this.hideBurnTokenModal.bind(this);
    }

    // Fetching
    async fetchTokens() {
        this.setState({isFetchingData: true});

        try {
            let tokenDetails = await this.fetchCachedTokenDetails();
            const issuedTokens = await fetchIssuedTokens(this.props.accountName);
            let tokens = [];

            for (let i = 0; i < issuedTokens.length; i++) {
                const issuedToken = issuedTokens[i];
                let details = tokenDetails[issuedToken.symbol];
                const expiry = issuedToken.expiry ? new Date(issuedToken.expiry + 'Z') : null; // expiry is provided in UTC
                const isExpired = expiry ? (expiry < Date.now()) : false;

                // The cache is most likely not completely current
                // (a new token could be create in the meantime)
                if (!details) {
                    this.invalidateTokenDetailsCache();
                    tokenDetails = await this.fetchCachedTokenDetails();
                    details = tokenDetails[issuedToken.symbol];
                }

                tokens.push({
                    id: issuedToken['id'],
                    issuer: details['issuer'],
                    owner: this.props.accountName,
                    name: details['token_name'],
                    symbol: issuedToken['symbol'],
                    description: details['description'],
                    iconURL: details['icon_url'],
                    expiry: expiry,
                    isExpired: isExpired,
                    isRevoked: issuedToken['revoked'],
                    isRevocable: !!details['revocable'],
                    isExpirable: !!details['expirable'],
                    isBurnable: !!details['burnable_by_owner']
                });
            }

            tokens.sort((a, b) => (b.id - a.id)); // From the newest to the latest
            this.setState({tokens});
        }
        catch (error) {
            this.setState({fetchingError: error});
            console.error(error);
        }

        this.setState({isFetchingData: false});
    }

    async fetchTokenDetails() {
        // At this stage just fetch all tokens' details in ~1 query
        // and simultaneously with the issued tokens rather than
        // waiting and then executing multiple queries for each
        // symbol as we can only use 1 node endpoint (usually slow)
        let tokens = await fetchCreatedTokens();

        // Make items (tokens) accessible by their symbol
        const tokensObject = tokens.reduce((obj, token) => {
            const symbol = token.supply.getTokenSymbol();
            return {...obj, [symbol]: token};
        }, {});

        return tokensObject;
    }

    async fetchCachedTokenDetails() {
        const STORAGE_KEY = this.#TOKEN_DETAILS_CACHE_KEY;

        // Cache the details for the current session as there is no need to
        // keep re-downloading them each time a new account is previewed
        if (STORAGE_KEY in sessionStorage) {
            return JSON.parse(sessionStorage.getItem(STORAGE_KEY));
        }
        else {
            const tokenDetails = await this.fetchTokenDetails();
            const stringified = JSON.stringify(tokenDetails);
            sessionStorage.setItem(STORAGE_KEY, stringified);
            return tokenDetails;
        }
    }

    retryTokensFetch() {
        this.setState({fetchingError: undefined});
        this.fetchTokens()
    }

    invalidateTokenDetailsCache() {
        sessionStorage.removeItem(this.#TOKEN_DETAILS_CACHE_KEY);
    }

    // TokenModals
    showRevokeTokenModal(token) {
        ReactGA.send({hitType: 'pageview', page: 'revoke-token'});
        this.setState({revokeToken: token});
    }

    hideRevokeTokenModal() {
        this.setState({revokeToken: undefined});
    };

    showBurnTokenModal(token) {
        ReactGA.send({hitType: 'pageview', page: 'burn-token'});
        this.setState({burnToken: token});
    }

    hideBurnTokenModal() {
        this.setState({burnToken: undefined});
    };

    // Components
    errorMessage() {
        let state = this.state;
        let message;

        if (!state.isValidAccountName) {
            message = 'Invalid Account Name';
        }
        else if (state.accountNameExists === false) {
            message ='The account name does not exist.';
        }
        else if (!state.isFetchingData && !state.fetchingError && !state.tokens.length) {
            message = 'No tokens found';
        }

        return message ? <span className="TokensResults-errorMessage">{message}</span> : null;
    }

    specialBadges() {
        const tokens = this.state.tokens;

        const specialTokens = [{
            symbol: 'BDGSPRT',
            text: 'SUPPORTER',
            style: 'primary'
        },  {
            symbol: 'DEGEN',
            text: 'DEGEN 💀',
            style: 'dark'
        }, {
            symbol: 'SCAM',
            text: 'SCAM',
            style: 'danger'
        }];

        const badges = specialTokens.map(specialToken => {
            let token = tokens.find(token => {
                return (token.symbol === specialToken.symbol && !token.isRevoked);
            });

            if (token) {
                const overlay = <Tooltip id="button-tooltip">{token.description}</Tooltip>;

                return (
                    <OverlayTrigger key={token.symbol} placement="top" delay={{show: 100, hide: 100}} overlay={overlay}>
                        <Badge className="TokensResults-badge" bg={specialToken.style}>{specialToken.text}</Badge>
                    </OverlayTrigger>
                );
            }

            return null;
        });

        return badges;
    }

    tokensGrid() {
        const tokens = this.state.tokens;

        if (!tokens.length) {
            return null;
        }

        // Since the currently logged in user can change,
        // provide this values dynamically when rendering
        const activeUser = this.sharedState.ual?.activeUser;

        const paddingClass = (index) => {
            // Supports 3 different layouts
            switch (index) {
                case 0: return 'pt-0';
                case 1: return 'pt-2 pt-md-0';
                case 2: return 'pt-2 pt-lg-0';
                default: return 'pt-2';
            }
        };

        const GridItem = (token, index) => {
            return (
                <Col sm={12} md={6} lg={4} key={token.id} className={paddingClass(index)}>
                    <TokenDetailsItem
                        token={token}
                        activeUserName={activeUser?.accountName}
                        burnToken={() => this.showBurnTokenModal(token) }
                        revokeToken={() => this.showRevokeTokenModal(token) }
                        onClick={() => this.openTokenDetails(token) }
                    />
                </Col>
            )
        };

        return (
            <Container className="TokensResults-tokensList" fluid>
                <Row>{tokens.map(GridItem)}</Row>
            </Container>
        )
    }

    // Misc
    setupDocumentTitle() {
        document.title = (this.props.accountName + ' - Tokens');
    }

    openTokenDetails(token) {
        this.props.navigate('/token/' + token.symbol);
    }

    // React
    componentDidMount() {
        if (this.state.isValidAccountName) {
            this.setState({isFetchingData: true});

            fetchAccount(this.props.accountName)
                .then(acc => {
                    this.setState({accountNameExists: true});
                    this.fetchTokens();
                })
                .catch(err => {
                    this.setState({
                        accountNameExists: false,
                        isFetchingData: false
                    });
                });
        }
    }

    render() {
        return (
            <>
                <RevokeTokenModal
                    isShown={!!this.state.revokeToken}
                    token={this.state.revokeToken}
                    onCloseClicked={this.hideRevokeTokenModal}
                    onTokenRevoked={() => { setTimeout(this.fetchTokens, 800) }}
                />

                <BurnTokenModal
                    isShown={!!this.state.burnToken}
                    token={this.state.burnToken}
                    onCloseClicked={this.hideBurnTokenModal}
                    onTokenBurned={() => { setTimeout(this.fetchTokens, 800) }}
                />

                {/* Main Modal */}
                <Container className="TokensResults-container" fluid="md">
                    {(this.state.isValidAccountName && !this.state.fetchingError) &&
                        <h2 className="TokensResults-accountName">{this.props.accountName}
                            {this.specialBadges()}
                        </h2>
                    }

                    {this.tokensGrid()}
                    {this.errorMessage()}

                    {this.state.fetchingError &&
                        <DetailedError message={this.state.fetchingError.message} onRetry={this.retryTokensFetch}/>
                    }

                    {/* If not completely removed, we'll have a gap at the end */}
                    {this.state.isFetchingData &&
                        <div className="TokensResults-loadingSpinnerWrapper">
                            <PulseLoader loading={this.state.isFetchingData}/>
                        </div>
                    }
                </Container>
            </>
        )
    }
};

function TokenDetailsItem(props) {
    const {token, activeUserName} = props;
    const {burnToken, revokeToken, onClick} = props;

    const stopPropagation = (func) => {
        return (event) => { func(); event.stopPropagation(); }
    };

    const TokenStatus = () => {
        if (token.isRevoked) {
            return <span className="TokensListItem-status invalid">Revoked</span>;
        }
        else if (token.isExpired) {
            return <span className="TokensListItem-status invalid">Expired</span>;
        }
        else if (token.expiry) {
            const isoDate = token.expiry.toISOString();
            const dateFormatted = token.expiry.toLocaleString();
            return <time className="TokensListItem-status" dateTime={isoDate}>Expires: {dateFormatted}</time>;
        }

        return null;
    };

    const DestroyTokenButton = () => {
        const isIssuerLoggedIn = (activeUserName === token.issuer);
        const isOwnerLoggedIn = (activeUserName === token.owner);

        if (isIssuerLoggedIn && token.isRevocable) {
            if (!token.isRevoked && !token.isExpired) {
                return <CloseButton
                        title="Revoke this token"
                        aria-label="Revoke"
                        className="TokensListItem-burnTokenButton revoke"
                        onClick={stopPropagation(revokeToken)}
                    />;
            }
        }

        if (isOwnerLoggedIn && token.isBurnable) {
            return <CloseButton
                    title="Burn this token"
                    aria-label="Burn"
                    className="TokensListItem-burnTokenButton burn"
                    onClick={stopPropagation(burnToken)}
                />;
        }

        return null;
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <b>ID:</b> #{token.id}, <b>Symbol:</b> {token.symbol},  <b>Issuer:</b> {token.issuer}
        </Tooltip>
    );

    return (
        <OverlayTrigger placement="top" delay={{show: 100, hide: 100}} overlay={renderTooltip}>
            <div className="TokensListItem-content" onClick={stopPropagation(onClick)}>
                <img src={token.iconURL || noImageFoundIcon} width="58px" height="58px" className="TokensListItem-icon" onError={(event => {
                    event.target.src = noImageFoundIcon;
                    event.onerror = null;
                })} alt="token-icon"/>

                <div className="TokensListItem-textContainer">
                    <span className="TokensListItem-tokenName">{token.name}</span>
                    <TokenStatus/>
                </div>

                <DestroyTokenButton/>
            </div>
        </OverlayTrigger>
    );
}

const WrappedTokensList = (props) => {
    // Use the `key` in order to not reuse the same component for different
    // accounts as that may cause an unpredictable issues due to the current state
    const accountName = useParams().accountName.toLowerCase();
    return <TokensList {...props} navigate={useNavigate()} accountName={accountName} key={accountName}/>
};

export default WrappedTokensList;
