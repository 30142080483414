import rpc from './EOSRPC';
import dfuseClient from './DfuseClient';
import { CONTRACT_NAME } from '../config';

export async function fetchToken(tokenSymbol) {
    const response = await rpc.get_table_rows({
        code: CONTRACT_NAME,
        scope: CONTRACT_NAME,
        table: 'stats',
        key_type: 'symbol_code',
        index_position: 1,
        lower_bound: tokenSymbol,
        upper_bound: tokenSymbol,
        limit: 1
    });

    return response.rows[0];
};

export async function fetchTokenByAccountName(accountName) {
    const response = await rpc.get_table_rows({
        code: CONTRACT_NAME,
        scope: CONTRACT_NAME,
        table: 'stats',
        key_type: 'name',
        index_position: 2,
        lower_bound: accountName,
        upper_bound: accountName,
        limit: 1
    });

    return response.rows[0];
};

export async function fetchCreatedTokens() {
    let tokens = [];
    let nextKey = null;

    do {
        const response = await rpc.get_table_rows({
            code: CONTRACT_NAME,
            scope: CONTRACT_NAME,
            table: 'stats',
            lower_bound: nextKey,
            limit: 300
        });

        nextKey = response['next_key']; // More results available
        tokens = tokens.concat(response.rows);
    } while (nextKey);

    return tokens;
};

export async function fetchIssuedTokens(owner) {
    let tokens = [];
    let nextKey = null;

    do {
        const response = await rpc.get_table_rows({
            code: CONTRACT_NAME,
            scope: owner,
            table: 'accounts',
            lower_bound: nextKey,
            limit: 300
        });

        nextKey = response['next_key']; // More results available
        tokens = tokens.concat(response.rows);
    } while (nextKey);

    return tokens;
};

export async function fetchIssuedTokensBulk(owners) {
    const MAX_CHUNK_SIZE = 600; // Set it to 600 even though up to 1500 should be supported
    let tables = [];

    for (let i = 0; i < owners.length; i += MAX_CHUNK_SIZE) {
        const scopes = owners.slice(i, i + MAX_CHUNK_SIZE);
        const params = {
            account: CONTRACT_NAME,
            table: 'accounts',
            scopes: scopes.join('|'),
            json: 'true'
        };

        const response = await dfuseClient.apiRequest('/v0/state/tables/scopes', 'GET', params);
        tables = tables.concat(response.tables);
    }

    return tables;
};

export async function fetchAllTokenOwners() {
    let accounts = [];
    let nextKey = null;

    do {
        const response = await rpc.get_table_by_scope({
            code: CONTRACT_NAME,
            table: 'accounts',
            lower_bound: nextKey,
            limit: 300
        });

        const moreAccounts = response.rows.map(obj => obj.scope);
        nextKey = response['more']; // More results available
        accounts = accounts.concat(moreAccounts);
    } while (nextKey);

    return accounts;
}

export async function fetchAccountNameSuggestions(accountName) {
    const response = await rpc.get_table_by_scope({
        code: 'eosio',
        table: 'userres',
        lower_bound: accountName,
        upper_bound: accountName.padEnd(12, 'z'),
        limit: 6
    });

    return response.rows.map(obj => obj.scope);
}

export async function fetchAccount(accountName) {
    return await rpc.get_account(accountName);
};
