import React from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import { EXPLORER_URL, transactionDetailsURL } from '../config';
import './ToastMessage.css';

function Error(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="error" show={props.isShown} onClose={props.onAutoClosed} delay={3500} autohide>
                <Toast.Header closeButton={false}>
                    <h5 className="toast-header-title error">Error</h5>
                </Toast.Header>

                <Toast.Body>{props.message}</Toast.Body>
           </Toast>
       </ToastContainer>
   );
}

function TransactionSigned(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="success" show={props.isShown} onClose={props.onAutoClosed} delay={3500} autohide>
                <Toast.Header closeButton={false}>
                    <h5 className="toast-header-title success">Success!</h5>
                </Toast.Header>

                <Toast.Body>
                    Transaction signed successfully! See on <a href={transactionDetailsURL(props.trxHash)} target="_blank" rel="noreferrer">{EXPLORER_URL.host}</a>
                </Toast.Body>
           </Toast>
       </ToastContainer>
   );
};

const ToastMessageTypes = {Error, TransactionSigned};
export default ToastMessageTypes;
