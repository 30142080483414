import './Footer.css';

function Footer() {
    return (
        <footer className="Footer">
            Copyright © {new Date().getFullYear()} <a href="mailto:baboon@badges.boo" className="Footer-link">Baboon</a>. All rights reserved.
        </footer>
    );
}

export default Footer;
