import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import ToastMessage from '../ToastMessage';
import ConfirmationModal from './ConfirmationModal';

import { SharedStateContext } from '../../Helpers/SharedState';
import { CONTRACT_NAME } from '../../config';

class CreateTokenModal extends React.Component {
    static contextType = SharedStateContext;

    get sharedState() {
        const [state] = this.context;
        return state;
    }

    constructor(props) {
        super(props);

        this.state = {
            isTransacting: false,
            errorMessage: undefined,
            trxHash: undefined,
            confirmationMessage: undefined
        };

        this.formData = {};

        this.hideConfirmationModal = this.hideConfirmationModal.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.hideSuccessMessage = this.hideSuccessMessage.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.createToken = this.createToken.bind(this);
        this.refreshTokenDetails = this.refreshTokenDetails.bind(this);
        this.close = this.close.bind(this);
    };

    // Token Creation
    handleFormSubmit(event) {
        const form = event.target;
        const unlimitedMaxSupply = '4611686018427387903'; // 2^62 - 1

        this.formData = {
            tokenName: form['tokenName'].value.trim(),
            description: form['tokenDescription'].value.trim(),
            iconURL: form['iconURL'].value.trim(),
            maxSupply: (form['maxSupply'].value || unlimitedMaxSupply),
            tokenSymbol: form['tokenSymbol'].value.toUpperCase(),
            revocable: form['revocable'].checked,
            burnableByOwner: form['burnable-by-owner'].checked,
            expirable: form['expirable'].checked
        };

        event.preventDefault();
        this.showConfirmationModal();
    };

    async createToken() {
        this.setState({isTransacting: true});

        try {
            const activeUser = this.sharedState.ual.activeUser;
            const trx = this.createTokenTransaction(activeUser, this.formData);
            const response = await activeUser.signTransaction(trx, {broadcast: true});

            this.close();
            this.showSuccessMessage(response.transactionId);
            setTimeout(this.refreshTokenDetails, 1000);
        }
        catch(error) {
            this.showErrorMessage(error);
        }

        this.setState({isTransacting: false});
    };

    createTokenTransaction(user, formData) {
        return {
            actions: [{
                 account: CONTRACT_NAME,
                 name: 'create',
                 authorization: [{
                     actor: user.accountName,
                     permission: 'active'
                 }],
                 data: {
                     'token_name': this.formData.tokenName,
                     'description': this.formData.description,
                     'icon_url': this.formData.iconURL,
                     'max_supply': (this.formData.maxSupply + ' ' + this.formData.tokenSymbol),
                     'revocable': this.formData.revocable,
                     'burnable_by_owner': this.formData.burnableByOwner,
                     'expirable': this.formData.expirable,
                     'issuer': user.accountName
                 }
             }]
        }
    };

    // Confirmation Modal
    showConfirmationModal() {
        /* eslint-disable no-multi-str */
        const message = 'A new token called "' + this.formData.tokenName + '" with a symbol \
            "' + this.formData.tokenSymbol + '" will be created. You will not be able to \
            change the mentioned details later. Are you sure you want to continue?'

        this.setState({confirmationMessage: message});
    };

    hideConfirmationModal() {
        this.setState({confirmationMessage: undefined});
    };

    // Toast Messages
    showErrorMessage(error) {
        if (error?.cause?.type === 'signature_rejected') {
            this.setState({errorMessage: 'The signature request rejected'});
        }
        else {
            // Scatter & Anchor produce different errors
            const cause = ('[' + (error?.cause?.message) + ']')
            this.setState({errorMessage: error.message + ' ' + cause});
            console.error(error?.cause || error);
        }
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    }

    showSuccessMessage(trxHash) {
        this.setState({trxHash});
    };

    hideSuccessMessage() {
        this.setState({trxHash: undefined});
    };

    // Form Validation
    setMaxSupplyValidityMessage(event) {
        const field = event.target;
        const validity = field.validity;

        if (!validity.valid && validity.patternMismatch) {
            field.setCustomValidity('Use a positive whole number or leave the field empty for an unlimited supply.')
        }
        else {
            field.setCustomValidity('');
        }
    };

    setTokenSymbolValidityMessage(event) {
        const field = event.target;
        const validity = field.validity;

        if (!validity.valid && validity.patternMismatch) {
            field.setCustomValidity('Use only A-Z characters.')
        }
        else {
            field.setCustomValidity('');
        }
    };

    // Misc
    refreshTokenDetails() {
        const [, {refreshToken}] = this.context;
        refreshToken();
    };

    close() {
        this.props.onCloseClicked();
    };

    render() {
        return (
            <>
                <ConfirmationModal
                    isShown={!!this.state.confirmationMessage}
                    title="Confirm Token Creation"
                    message={this.state.confirmationMessage}
                    cancelButtonTitle="Back"
                    confirmButtonTitle="Create"
                    onCancelClicked={this.hideConfirmationModal}
                    onConfirmClicked={() => { this.hideConfirmationModal(); this.createToken(); }}
                />

                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />

                <ToastMessage.TransactionSigned
                    isShown={!!this.state.trxHash}
                    trxHash={this.state.trxHash}
                    onAutoClosed={this.hideSuccessMessage}
                />

                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} contentClassName="modal-content-dark" backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title>Create a Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form id="createTokenForm" onSubmit={this.handleFormSubmit} autoComplete="off"> {/* Autocomplete changes style of the input */}
                            <Form.Group className="mb-3" controlId="tokenName">
                                <Form.Label>Token's Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" maxLength="50" autoFocus required/>
                                <Form.Text className="text-muted">For example: Fire Safety Certificate.</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tokenDescription">
                                <Form.Label>Token's Description</Form.Label>
                                <Form.Control as="textarea" className="capitalizeFirstLetter" placeholder="Description (Optional)" rows={3} maxLength="150"/>
                                <Form.Text className="text-muted">Optionally describe purpose of this token.</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="iconURL">
                                <Form.Label>Token's icon</Form.Label>
                                <Form.Control type="url" placeholder="https://path.to/icon" maxLength="100" required/>
                                <Form.Text className="text-muted">Try using the resolution of at least 256x256.</Form.Text>
                            </Form.Group>

                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="maxSupply">
                                    <Form.Label>Max Supply</Form.Label>
                                    <Form.Control type="text" placeholder="123" maxLength="19" pattern="([1-9][0-9]{0,18})" onChange={this.setMaxSupplyValidityMessage}/>
                                    <Form.Text className="text-muted">Leave empty for an unlimited supply.</Form.Text>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="tokenSymbol">
                                    <Form.Label>Token's Symbol</Form.Label>
                                    <Form.Control type="text" placeholder="SBT" maxLength="7" style={{textTransform: 'uppercase'}} pattern="[a-zA-Z]{1,7}" onChange={this.setTokenSymbolValidityMessage} required/>
                                </Form.Group>
                            </Row>

                            <Form.Group>
                                <Form.Check id="revocable" type="checkbox" label="Revocable" title="The token will be revocable by its issuer" inline/>
                                <Form.Check id="burnable-by-owner" type="checkbox" label="Burnable by owner" title="The token will be burnable by its owner" inline defaultChecked="true"/>
                                <Form.Check id="expirable" type="checkbox" label="Expirable" title="The token will expire at certain time" inline/>
                            </Form.Group>
                        </Form>

                        <p style={{color: 'var(--grey-form)', margin: '1rem 0 0 0', textAlign: 'center'}}>Please note, you can only create 1 token per account.</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {!this.state.isTransacting &&
                            <Button variant="secondary" onClick={this.close}>Cancel</Button>
                        }

                        <Button type="submit" form="createTokenForm" variant="primary" disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">Confirm</span>
                                </>
                                :
                                'Confirm'
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };
};

export default CreateTokenModal;
