import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga4';

import CreateTokenModal from './Modals/CreateTokenModal';
import IssueTokenModal from './Modals/IssueTokenModal';
import { SharedStateContext } from '../Helpers/SharedState';

import './Home.css';
import networkImage from '../Assets/Network.png';

function Home() {
    React.useEffect(() => { document.title = 'Create and explore Soulbound tokens on EOS' }, []);

    const [createTokenModalShown, setCreateTokenModalShown] = React.useState(false);
    const [issueTokenModalShown, setIssueTokenModalShown] = React.useState(false);

    const [state] = React.useContext(SharedStateContext);
    const {ual, sbtToken} = state;

    const actionButtonState = (() => {
        if (ual.activeUser) {
            return sbtToken ? 'issue_token' : 'create_token';
        }
        else {
            return 'get_started';
        }
    })();

    const actionButtonClicked = () => {
        switch (actionButtonState) {
        case 'get_started':
            ual.showModal();
            break;
        case 'create_token':
            ReactGA.send({hitType: 'pageview', page: 'create-token'});
            setCreateTokenModalShown(true);
            break;
        case 'issue_token':
            ReactGA.send({hitType: 'pageview', page: 'issue-token'});
            setIssueTokenModalShown(true);
            break;
        default:
            console.error('Unknown case');
        }
    };

    const buttonTitle = () => {
        switch (actionButtonState) {
        case 'get_started':
            return 'Get Started';
        case 'create_token':
            return 'Create a Token';
        case 'issue_token':
            return 'Issue a Token';
        default:
            console.error('Unknown case');
        }
    };

    return (
        <>
            <Container className="Home-container" fluid="lg">
                <Row className="align-items-center">
                    <Col>
                        <div className="heading-container">
                            <h1 className="heading">Soulbound Tokens Explorer</h1>
                            <div className="subtitle">Get started with the easiest platform to create and explore Soulbound<br/>tokens on EOS.</div>
                            <Button variant="primary" className="action-button" onClick={actionButtonClicked}>{buttonTitle()}</Button>
                        </div>
                    </Col>

                    <Col className="text-end d-none d-md-block">
                        <img src={networkImage} className="network-image" alt="network"/>
                    </Col>
                </Row>
            </Container>

            {/* MODALS */}
            <CreateTokenModal isShown={createTokenModalShown} onCloseClicked={() => setCreateTokenModalShown(false) }/>
            <IssueTokenModal isShown={issueTokenModalShown} onCloseClicked={() => setIssueTokenModalShown(false) }/>
        </>
    );
}

export default Home;
