import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './ConfirmationModal.css';

function ConfirmationModal(props) {
    return (
        <Modal
            show={props.isShown}
            className="confirmation-modal"
            contentClassName="modal-content-dark"
            backdropClassName="confirmation-modal-backdrop"
            backdrop="static"
            centered
            >

            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{props.message}</Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.onCancelClicked}>
                    {props.cancelButtonTitle || 'Cancel'}
                </Button>

                <Button variant="danger" onClick={props.onConfirmClicked}>
                    {props.confirmButtonTitle || 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;
