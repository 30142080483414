import React from 'react';
import { UALContext } from '@adriexnet/ual-reactjs-renderer';
import { fetchTokenByAccountName } from '../Helpers/BadgesRPC';

let isFetching = false;

export const SharedStateContext = React.createContext();

/* eslint-disable react-hooks/exhaustive-deps */
// Adding sbtToken or some of the funcs into dependencies
// makes no sense. We don't care about them changing and
// some even can't. Hence disable this stupid rule
export const SharedStateProvider = (props) => {
    const ual = React.useContext(UALContext);
    const [state, setState] = React.useState({
        ual: ual,
        sbtToken: undefined // `null` in case of a non-existing token
    });

    const sbtToken = state.sbtToken;

    const updateState = (newProps) => {
        setState(prevState => ({...prevState, ...newProps}));
    };

    const setToken = sbtToken => { updateState({sbtToken}) };
    const setUAL = ual => { updateState({ual}) };

    const refreshToken = () => {
        if (!isFetching) {
            isFetching = true;

            fetchTokenByAccountName(ual.activeUser.accountName)
                .then(token => setToken(token || null)) // Make sure it's null to indicate it's non-existing
                .catch(error => console.error(error))
                .finally(() => { isFetching = false });
        }
    };

    if (ual !== state.ual) {
        setUAL(ual);
    }

    React.useEffect(() => {
        // `sbtToken` being `null` is indicative of a non-existing token
        if (!ual.activeUser && sbtToken !== undefined) {
            setToken(undefined); // clear up after logout
        }
        else if (ual.activeUser && sbtToken === undefined) {
            refreshToken();
        }
    }, [ual.activeUser]);

    return (
        <SharedStateContext.Provider value={[state, {refreshToken}]}>
            {props.children}
        </SharedStateContext.Provider>
    );
};
