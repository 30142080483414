import React from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import { fetchToken, fetchIssuedTokensBulk, fetchAllTokenOwners } from '../Helpers/BadgesRPC';
import DetailedError from './Widgets/DetailedError';
import PulseLoader from './Widgets/PulseLoader';
import '../Helpers/StringUtils';
import * as utils from '../Helpers/Utils';

import './TokenDetails.css';
import noImageFoundIcon from '../Assets/NoImageFoundIcon.png';

class TokenDetails extends React.Component {
    constructor(props) {
        super(props);

        const isValidTokenSymbol = utils.isValidTokenSymbol(props.tokenSymbol);

        this.state = {
            isValidTokenSymbol: isValidTokenSymbol,
            isFetchingData: false,
            fetchingError: undefined,
            token: undefined,
            owners: []
        };

        this.setupDocumentTitle();
        this.fetchRequiredDetails = this.fetchRequiredDetails.bind(this);
    }

    // Fetching
    async fetchTokenOwners() {
        this.setState({isFetchingData: true});

        try {
            const accounts = await fetchAllTokenOwners();
            const issuedTokens = await fetchIssuedTokensBulk(accounts);
            const tokenSymbol = this.props.tokenSymbol;
            const owners = [];

            for (let i = 0; i < issuedTokens.length; i++) {
                const token = issuedTokens[i];
                const accountOwnsToken = token.rows.find(row => {
                    return (row.json.symbol === tokenSymbol && !row.json.revoked);
                });

                if (accountOwnsToken) {
                    owners.push(token.scope);
                }
            }

            owners.sort((a, b) => a.localeCompare(b)); // Sort alphabetically
            this.setState({owners});
        }
        catch (error) {
            this.setState({fetchingError: error});
            console.error(error);
        }

        this.setState({isFetchingData: false});
    }

    fetchRequiredDetails() {
        this.setState({fetchingError: undefined}); // The func gets called when retry-ing fetch too

        if (this.state.token) {
            this.fetchTokenOwners();
        }
        else {
            this.setState({isFetchingData: true});

            fetchToken(this.props.tokenSymbol)
                .then(token => {
                    if (token) {
                        const wasIssued = !!token.supply.getSupplyAmount();

                        this.setState({
                            token: token,
                            isFetchingData: wasIssued
                        }, () => { wasIssued && this.fetchTokenOwners() });
                    }
                    else {
                        this.setState({
                            token: null,
                            isFetchingData: false
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        fetchingError: error,
                        isFetchingData: false
                    });
                });
        }
    }

    // Components
    tokenDetailsHeader() {
        const token = this.state.token;

        if (!token) {
            return null
        }

        const issuer = token['issuer'];

        return (
            <>
                <div className="TokenDetails-header">
                    <img src={token['icon_url'] || noImageFoundIcon} width="84px" height="84px" className="TokenDetails-icon" onError={(event => {
                        event.target.src = noImageFoundIcon;
                        event.onerror = null;
                    })} alt="token-icon"/>

                    <div className="TokenDetails-headerTextContainer">
                        <h2 className="TokenDetails-tokenName">{token['token_name']}</h2>
                        <span className="TokenDetails-issuer"><b>{this.props.tokenSymbol}</b> | <Link to={`/account/${issuer}`}>{issuer}</Link></span>
                    </div>
                </div>

                <span className="TokenDetails-description">{token['description']}</span>
            </>
        );
    }

    ownersList() {
        const owners = this.state.owners;

        if (this.state.isFetchingData || this.state.fetchingError || this.state.token === null) {
            return null;
        }

        return (
            <div>
                <span className="TokenDetails-sectionTitle">Token Owners ({owners.length}):</span>

                <ListGroup style={{marginTop: '0.5rem'}}>
                    {
                        owners.map(owner => {
                            return (
                                <ListGroup.Item className="TokenDetails-tokenOwnersListItem" key={owner} as={Link} to={'/account/' + owner}>
                                    {owner}
                                </ListGroup.Item>
                            );
                        })
                    }
                </ListGroup>
            </div>
        );
    }

    errorMessage() {
        if (!this.state.isValidTokenSymbol) {
            return <span className="TokenDetails-errorMessage">Invalid Token Symbol</span>;
        }
        else if (this.state.token === null) {
            return <span className="TokenDetails-errorMessage">The token does not exist</span>;
        }

        return null;
    }

    // Misc
    setupDocumentTitle() {
        document.title = (this.props.tokenSymbol + ' - Details');
    }

    // React
    componentDidMount() {
        if (this.state.isValidTokenSymbol) {
            this.fetchRequiredDetails();
        }
    }

    render() {
        return (
            <Container className="TokenDetails-container" fluid="sm">
                {this.tokenDetailsHeader()}
                {this.ownersList()}
                {this.errorMessage()}

                {this.state.fetchingError &&
                    <DetailedError message={this.state.fetchingError.message} onRetry={this.fetchRequiredDetails}/>
                }

                {/* If not completely removed, we'll have a gap at the end */}
                {this.state.isFetchingData &&
                    <div className="TokenDetails-loadingSpinnerWrapper">
                        <PulseLoader loading={true}/>
                        {/* Start showing only once we're fetching owners (the header is shown) */}
                        {this.state.token && <span style={{color: 'var(--white-inactive)'}}>This may take a while</span>}
                    </div>
                }
            </Container>
        )
    }
}

const WrappedTokenDetails = (props) => {
    // Use the `key` in order to not reuse the same component for different
    // accounts as that may cause an unpredictable issues due to the current state
    const tokenSymbol = useParams().tokenSymbol;
    return <TokenDetails {...props} tokenSymbol={tokenSymbol} key={tokenSymbol}/>
};

export default WrappedTokenDetails;
