import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import ToastMessage from '../ToastMessage';
import { SharedStateContext } from '../../Helpers/SharedState';
import { CONTRACT_NAME } from '../../config';
import '../../Helpers/StringUtils';

class IssueTokenModal extends React.Component {
    static contextType = SharedStateContext;
     #MAX_RECIPIENTS = 300;

    get sharedState() {
        const [state] = this.context;
        return state;
    }

    constructor(props) {
        super(props);

        this.state = {
            isTransacting: false,
            errorMessage: undefined,
            trxHash: undefined
        };

        this.formData = {};

        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.hideSuccessMessage = this.hideSuccessMessage.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.setRecipientValidityMessage = this.setRecipientValidityMessage.bind(this);
        this.close = this.close.bind(this);
    };

    // Token Issuance
    handleFormSubmit(event) {
        const form = event.target;
        const expiryLocal = form['tokenExpiry']?.value?.trim();

        const recipients = form['recipient']
            .value
            .split(',')
            .filter(item => item)
            .map(item => item.trim());

        this.formData = {
            recipients: recipients,
            tokenSymbol: form['tokenSymbol'].value.trim().toUpperCase(),
            tokenExpiry: this.getUTCDateFromString(expiryLocal),
            metadata: form['metadata'].value.trim() || undefined,
        };

        event.preventDefault();
        this.issueToken();
    };

    async issueToken() {
        this.setState({isTransacting: true});

        try {
            const activeUser = this.sharedState.ual.activeUser;
            const trx = this.issueTokenTransaction(activeUser, this.formData);
            const response = await activeUser.signTransaction(trx, {broadcast: true});

            this.close()
            this.showSuccessMessage(response.transactionId);
        }
        catch(error) {
            this.showErrorMessage(error);
        }

        this.setState({isTransacting: false});
    };

    issueTokenTransaction(user, formData) {
        const actor = user.accountName;

        const actions = this.formData.recipients
            .map(recipient => {
                return {
                    account: CONTRACT_NAME,
                    name: 'issue',
                    authorization: [{
                        actor: actor,
                        permission: 'active'
                    }],
                    data: {
                        'to': recipient,
                        'token_symbol': this.formData.tokenSymbol,
                        'expiry': this.formData.tokenExpiry,
                        'meta_data': this.formData.metadata
                    }
                }
            });

        return {actions};
    };

    // Toast Messages
    showErrorMessage(error) {
        if (error?.cause?.type === 'signature_rejected') {
            this.setState({errorMessage: 'The signature request rejected'});
        }
        else {
            // Scatter & Anchor produce different errors
            const cause = ('[' + (error?.cause?.message ?? error?.error?.what) + ']')
            this.setState({errorMessage: error.message + ' ' + cause});
            console.error(error?.cause || error);
        }
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    };

    showSuccessMessage(trxHash) {
        this.setState({trxHash});
    };

    hideSuccessMessage() {
        this.setState({trxHash: undefined});
    };

    // Misc
    // The contract and `time_point_sec` in particular expects
    // the date in ISO 8601 format (in UTC) without a timezone modifier
    getUTCDateFromString(string) {
        // Avoid returning back (using) an empty string
        if (!string) {
            return undefined;
        }

        const date = new Date(string);
        const utcDate = new Date(date.toUTCString());
        const isoFormatted = utcDate.toISOString().replace('Z', ''); // Action fails if the Z is present
        return isoFormatted;
    };

    setRecipientValidityMessage(event) {
        const field = event.target;
        const numberOfCommas = (field.value.match(/,/g) || []).length;
        const potentialAccounts = (numberOfCommas + 1);

        if (potentialAccounts > this.#MAX_RECIPIENTS) {
            field.setCustomValidity(`You can specify up to ${this.#MAX_RECIPIENTS} names. Your list currently consists of ${numberOfCommas} account names.`);
        }
        else {
            field.setCustomValidity('');
        }
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    render() {
        const sbtToken = this.sharedState.sbtToken; // Might be `undefined` on couple of first renders
        const tokenSymbol = sbtToken?.['max_supply']?.getTokenSymbol();
        const isExpirable = !!(sbtToken?.['expirable']); // 0/1 by default

        return (
            <>
                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />

                <ToastMessage.TransactionSigned
                    isShown={!!this.state.trxHash}
                    trxHash={this.state.trxHash}
                    onAutoClosed={this.hideSuccessMessage}
                />

                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} contentClassName="modal-content-dark" backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title>Issue a Token</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form id="issueTokenForm" onSubmit={this.handleFormSubmit} autoComplete="off"> {/* Autocomplete changes style of the input */}
                            <Form.Group className="mb-3" controlId="recipient">
                                <Form.Label>Recipient</Form.Label>
                                <Form.Control type="text" placeholder="Account name to receive the token" onChange={this.setRecipientValidityMessage} autoFocus required/>
                                <Form.Text className="text-muted">You can paste in a comma-separated list of up to {this.#MAX_RECIPIENTS} accounts.</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tokenSymbol">
                                <Form.Label>Token's Symbol</Form.Label>
                                <Form.Control type="text" maxLength="7" placeholder="SBT" value={tokenSymbol} disabled={tokenSymbol} style={{textTransform: 'uppercase'}} required/>
                            </Form.Group>

                            {isExpirable &&
                                <Form.Group className="mb-3" controlId="tokenExpiry">
                                    <Form.Label>Expiration</Form.Label>
                                    <Form.Control type="datetime-local" required/>
                                </Form.Group>
                            }

                            <Form.Group className="mb-3" controlId="metadata">
                                <Form.Label>Metadata</Form.Label>
                                <Form.Control type="text" maxLength="512" placeholder="Metadata (Optional)"/>
                                <Form.Text className="text-muted">Optional data you'd like to associate with this token.</Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        {!this.state.isTransacting &&
                            <Button variant="secondary" onClick={this.close}>Cancel</Button>
                        }

                        <Button type="submit" form="issueTokenForm" variant="primary" disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">Confirm</span>
                                </>
                                :
                                'Confirm'
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
         );
    };
}

export default IssueTokenModal;
