import Button from 'react-bootstrap/Button';

export default function DetailedError(props) {
    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        marginTop: '20px',
        marginBottom: '20px'
    };

    return (
        <div className="detailedErrorWrapper" style={styles}>
            <h1 style={{color: 'var(--white-active)'}}>Error</h1>
            <span style={{color: 'var(--white-inactive)', maxWidth: '280px', textAlign: 'center'}}>{props.message}</span>
            <Button variant="primary" style={{marginTop: '4px'}} onClick={props.onRetry}>Retry</Button>
        </div>
    );
}
