 // - The original `ual-reactjs-renderer` depends on the `react-icons`
 // where due to ~2 icons the entire package gets included and ends
 // up making half the size of the entire app.
 // - Avoid using `withUAL` as it resets the global shared context
import { UALProvider } from '@adriexnet/ual-reactjs-renderer';
import { Anchor } from 'ual-anchor';
import { Scatter } from 'ual-scatter';
import { Wombat } from 'wombat-ual';
import { TokenPocket } from '@tp-lab/ual-token-pocket';
import { BLOCKCHAIN } from '../config';

const appName = 'Badges';
const anchor = new Anchor([BLOCKCHAIN], {appName});
const scatter = new Scatter([BLOCKCHAIN], {appName});
const wombat = new Wombat([BLOCKCHAIN], {appName});
const tp = new TokenPocket([BLOCKCHAIN]);

function BadgesUALProvider(props) {
    return (
        <UALProvider appName={appName} authenticators={[anchor, scatter, wombat, tp]} chains={[BLOCKCHAIN]}>
            {props.children}
        </UALProvider>
    );
};

export default BadgesUALProvider;
