import React from 'react';
import Stack from 'react-bootstrap/Stack';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';

import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home';
import TokensList from './Components/TokensList';
import TokenDetails from './Components/TokenDetails';
import PageNotFound from './Components/PageNotFound';

import { SharedStateProvider } from './Helpers/SharedState';
import BadgesUALProvider from './Helpers/BadgesUALProvider';

import './App.css';

function App() {
    const location = useLocation();

    React.useEffect(() => {
        ReactGA.send({hitType: 'pageview', page: (location.pathname + location.search)});
    }, [location]);

    return (
        <BadgesUALProvider>
            <SharedStateProvider>
                <Stack className="App">
                    <Header/>
                        <div className="App-content">
                            <Routes>
                                <Route exact path="/" element={<Home/>}/>
                                <Route path="/account/:accountName" element={<TokensList/>}/>
                                <Route path="/token/:tokenSymbol" element={<TokenDetails/>}/>
                                <Route path="*" element={<PageNotFound/>}/>
                            </Routes>
                        </div>
                    <Footer/>
                </Stack>
            </SharedStateProvider>
        </BadgesUALProvider>
    );
}

export default App;
